var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_vm.detailTransaksi ? _c('div', {
    staticClass: "card",
    attrs: {
      "id": "printableArea"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('h3', [_c('b', [_vm._v("INVOICE")])]), _c('h2')]), _c('span', {
    staticClass: "text--primary h6"
  }, [_c('b', [_vm._v(_vm._s(_vm.detailTransaksi.no_invoice))])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-sm"
  }, [_c('h5', [_c('b', [_vm._v("DITERBITKAN ATAS NAMA")])]), _vm._v(" Penjual : "), _c('b', [_vm._v(_vm._s(_vm.user.karyawan.nama_lengkap))])]), _c('div', {
    staticClass: "col-sm"
  }, [_c('h5', [_c('b', [_vm._v("UNTUK")])]), _c('table', [_c('tr', [_c('td', {
    attrs: {
      "width": "200px"
    }
  }, [_vm._v("Pembeli")]), _c('td', [_c('b', [_vm._v(" : " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer ? _vm.detailTransaksi.customer.nama : "-"))])])]), _c('tr', [_c('td', [_vm._v("Tanggal Pembelian")]), _c('td', [_c('b', [_vm._v(": " + _vm._s(_vm.humanDate(_vm.detailTransaksi.waktu_transaksi)))])])]), _c('tr', [_c('td', [_vm._v("No. Telepon")]), _c('td', [_c('b', [_vm._v(": " + _vm._s(_vm.detailTransaksi.customer ? _vm.detailTransaksi.customer.no_hp : "-"))])])]), _c('tr', [_c('td', {
    staticStyle: {
      "display": "flex"
    }
  }, [_vm._v("Alamat Pembelian")]), _c('td', [_c('b', [_vm._v(": " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer && _vm.detailTransaksi.customer.data_pengiriman ? _vm.detailTransaksi.customer.data_pengiriman.alamat : "-"))]), _vm._v(", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer && _vm.detailTransaksi.customer.data_pengiriman ? _vm.detailTransaksi.customer.data_pengiriman.desa.name : "-") + ", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer && _vm.detailTransaksi.customer.data_pengiriman ? _vm.detailTransaksi.customer.data_pengiriman.kecamatan.name : "-") + ", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer && _vm.detailTransaksi.customer.data_pengiriman ? _vm.detailTransaksi.customer.data_pengiriman.kab_kota.name : "-") + ", " + _vm._s(_vm.detailTransaksi && _vm.detailTransaksi.customer && _vm.detailTransaksi.customer.data_pengiriman ? _vm.detailTransaksi.customer.data_pengiriman.provinsi.name : "-") + " ")])])])])]), _c('div', {
    staticClass: "table-responsive mt-5"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("INFO PRODUK")]), _c('th', [_vm._v("JUMLAH")]), _c('th', [_vm._v("HARGA BARANG (Rp)")]), _c('th', [_vm._v("TOTAL HARGA (Rp)")])])]), _c('tbody', _vm._l(_vm.detailTransaksi.item, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_c('div', {
      staticClass: "text--primary"
    }, [_vm._v(_vm._s(item.varian))]), _c('div', {
      staticClass: "text--primary"
    }, [_vm._v(_vm._s(item.barang_nama))])]), _c('td', [_vm._v(_vm._s(item.qty))]), _c('td', [_vm._v(_vm._s(_vm.formatRupiah(item.harga)))]), _c('td', [_vm._v(_vm._s(_vm.formatRupiah(item.total_harga)))])]);
  }), 0)])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_vm._v(" Catatan : "), _c('br'), _vm._v(" " + _vm._s(_vm.detailTransaksi.catatan) + " ")]), _c('div', {
    staticClass: "col-sm"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('b', [_vm._v("TOTAL HARGA")])]), _c('div', [_c('b', [_vm._v("Rp. " + _vm._s(_vm.formatRupiah(_vm.detailTransaksi.uang_masuk)))])])]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('b', [_vm._v("ONGKIR")])]), _c('div', [_c('b', [_vm._v("Rp. " + _vm._s(_vm.formatRupiah(_vm.detailTransaksi.ongkir)))])])]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('b', [_vm._v("TOTAL ORDER")])]), _c('div', [_c('b', [_vm._v("Rp. " + _vm._s(_vm.formatRupiah(_vm.detailTransaksi.uang_masuk)))])])])])]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_vm._v(" Metode Pembayaran : "), _c('h6', [_vm.detailTransaksi.metode_bayar == 'manual_transfer' ? _c('b', [_vm._v(" Transfer Manual " + _vm._s(_vm.detailTransaksi.kode_bayar_detail ? _vm.detailTransaksi.kode_bayar_detail.deskripsi : "") + " " + _vm._s(_vm.detailTransaksi.kode_bayar_detail ? _vm.detailTransaksi.kode_bayar_detail.no_rekening : ""))]) : _c('b', [_vm._v(" " + _vm._s(_vm.metodeBayarUpper) + " ")])])]), _vm.detailTransaksi.transaction_type != 'jasa' ? _c('div', {
    staticClass: "col-sm-6"
  }, [_vm._v(" Kurir : "), _c('h6', [_c('b', [_vm._v(_vm._s(_vm.detailTransaksi.nama_kurir) + " - " + _vm._s(_vm.detailTransaksi.no_resi) + " ")]), _vm._v(" "), _vm.detailTransaksi.label_pengiriman ? _c('b-button', {
    attrs: {
      "id": "tombol",
      "variant": "warning",
      "size": "sm",
      "target": "_blank",
      "href": 'https://api-heykama.notive.my.id' + _vm.detailTransaksi.label_pengiriman
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "DownloadIcon"
    }
  }), _vm._v("Download Label Pengiriman")], 1) : _vm._e()], 1)]) : _vm._e()])])]) : _vm._e(), _c('b-button', {
    staticClass: "text-center w-100",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.printContent
    }
  }, [_vm._v("Print")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }